import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from 'lodash'
class LayeredColumnChart extends Component {
  chart = null; // Store chart instance

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data
    ) {

      this.updateChartData();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  initChart() {
    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(this.props.id || "LayeredColumnChart", am4charts.XYChart);
    // this.chart.numberFormatter.numberFormat = "#.#'%'";

    // Create category axis (X-axis)
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.horizontalCenter = "middle"; // Centers text
    categoryAxis.renderer.labels.template.verticalCenter = "middle"; // Centers vertically
    categoryAxis.renderer.labels.template.textAlign = "middle"; // Ensures proper alignment
    categoryAxis.renderer.labels.template.valign = "top";
    categoryAxis.renderer.labels.template.rotation = -70; // Rotates labels to be vertical
    categoryAxis.renderer.labels.template.dy = -10; // Moves labels down
     categoryAxis.renderer.labels.template.wrap = true; // Wraps long labels
    categoryAxis.renderer.labels.template.maxWidth = 100; // Limits label width
      categoryAxis.renderer.labels.template.fontSize = 11; // Set font size (adjust as needed)

      categoryAxis.renderer.labels.template.fill = am4core.color("#000000"); // Text color
      categoryAxis.renderer.labels.template.fontFamily = "Segoe UI"; // Choose font family

          categoryAxis.renderer.labels.template.maxHeight = 5; // Set maximum height


          
    // Create value axis (Y-axis)
    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "% of Houses Completed";
    valueAxis.title.fontWeight = "bold";
    // Set Y-Axis minimum and maximum values
    valueAxis.min = 0;
    valueAxis.max = 100;
    // Ensure values fit within the range
    valueAxis.strictMinMax = true;

    // Create Series 1 (2004)
    let series1 = this.chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "HousesSanctioned";
    series1.dataFields.categoryX = "country";
    series1.clustered = false;
    series1.tooltipText = "Sanctioned in {categoryX}: [bold]{SanctionValue}[/]";
    series1.columns.template.fillOpacity = 0.7;
    series1.columns.template.fill = am4core.color("#ffdd00");
    series1.columns.template.strokeOpacity = 0;
// ffdd00
    // Create Series 2 (2005)
    let series2 = this.chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "Completed";
    series2.dataFields.categoryX = "country";
    series2.clustered = false;
    series2.columns.template.width = am4core.percent(50);
    series2.columns.template.fill = am4core.color("#00b09a");
    series2.tooltipText = "Completed in {categoryX} : [bold]{CompletedValue}[/]";
    series2.columns.template.strokeOpacity = 0;

// Add Bullet on series 2

// 🏆 ADD BULLET WITH VALUE
let bullet = series2.bullets.push(new am4charts.LabelBullet());
bullet.label.text = "{Completed}";  // Display CompletedValue
bullet.label.fill = am4core.color("#000000"); // Text color (Black)
bullet.label.dy = -10; // Position above the bar
 bullet.label.fontSize = 12; // Adjust font size
bullet.label.fontWeight = "bold"; // Make text bold
// bullet.label.rotation = -45;
bullet.label.rotation = 0; // No rotation (horizontal text)
bullet.label.horizontalCenter = "middle"; // Center horizontally
bullet.label.verticalCenter = "middle"; // Center vertically
bullet.locationY = 0.5; // Moves label to the center of the column

bullet.label.adapter.add("fontSize", function (size, target) {
  return target.dataItem && target.dataItem.valueY > 90 ? 10 : 14;  
});

// DYNAMIC COLOR CHANGE FOR BETTER VISIBILITY
bullet.label.adapter.add("fill", function (fill, target) {
  return target.dataItem && target.dataItem.valueY > 90 ? am4core.color("#ffffff") : am4core.color("#ffffff");
});

//  MOVE LABEL OUTSIDE IF BAR TOO SMALL
bullet.label.adapter.add("locationY", function (location, target) {
  return target.dataItem && target.dataItem.valueY > 90 ? -0.3 : 0.5;  
});



//  bullet.label.horizontalCenter = "center";  // Align left for full visibility
// bullet.label.textAlign = "start";  // Ensure full text is visible
// bullet.locationX = 1;  // Position labels outside the column if needed
// bullet.label.dx = 15;  // Adjust horizontal position to move it outside
// bullet.label.truncate = false;  // Prevent cutting text
// bullet.label.hideOversized = false;  // Always show full value


// End 

    // Enable cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;




   
    this.updateChartData();
  }

  updateChartData() {
    if (!this.props.data) return;


    const data1 = this.prepareChartData(this.props.data);
    if (this.chart) {


          // Add scrollbar
          this.chart.scrollbarX = new am4core.Scrollbar();
          this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;
          this.chart.scrollbarX.start = 0; // Start at first data point
          this.chart.scrollbarX.end = data1.length > 36 ? 0.8 : 1 // Show only 20% of total data (approx. 20 items)
          this.chart.cursor.behavior = "panX";
        
          this.chart.scrollbarX.events.on("positionchanged", (event) => {
            console.log("Scroll Position:", event.target.start, event.target.end);
          });

     
          // Ensure scrollbar refreshes
          setTimeout(() => {
            this.chart.scrollbarX.invalidate();
          }, 100);

      this.chart.data = data1;
    }
  }

  prepareChartData(graphData) {



return graphData;

    
//     // Convert graphData into a suitable format for amCharts

//     const { data = [], isAllIndia, isState, isDist, statesList = [], districtList = [], cityList = [] } = this.props;
//     console.log('data---->>>>>>>>>>under New graapg===>>>>.', data);
//     console.log(districtList, 'data---->>>>>>>>>>under New graapg===>>>>.', statesList, 'city--', cityList);
//     const groupOrder = ["States", "Union Territories", "North East State"]

//     const fnlGraphData = _.map(isAllIndia ? statesList : isState ? districtList : isDist ? cityList : [], (obj) => {
//       const rowCode = isAllIndia ? _.get(obj, 'statecode') : isState ? _.get(obj, 'DistrictCode') : isDist ? _.get(obj, 'CityCode') : ''
//       const rowName = isAllIndia ? _.get(obj, 'statename') : isState ? _.get(obj, 'DistrictName') : isDist ? _.get(obj, 'CityName') : ''

//       const groupData = _.filter(data, f => isAllIndia ? _.get(f, 'StateCode') === rowCode : isState ? _.get(f, 'DistrictCode') === rowCode : isDist ? _.get(f, 'CityCode') === rowCode : false)
//       // console.log('groupData--->>>>>>>',groupData)

//       const gSanctioned = _.sumBy(groupData, sm => Number(_.get(sm, 'HousesSanctionedEnahncement') || 0) + Number(_.get(sm, 'HousesSanctionedNew') || 0))
//       const gGrounded = _.sumBy(groupData, sm => _.get(sm, 'totalWorkOrders') ? _.get(sm, 'totalWorkOrders') : 0)
//       const gCompleted = _.sumBy(groupData, sm => _.get(sm, 'completed') ? _.get(sm, 'completed') : 0)
//       const scoreGrounded = Math.round((gGrounded / gSanctioned) * 100) > 100 ? 100 : Math.round((gGrounded / gSanctioned) * 100)
//       const scoreCompleted = Math.round((gCompleted / gSanctioned) * 100) > 100 ? 100 : Math.round((gCompleted / gSanctioned) * 100)
// console.log('isAllIndia--->>>',isAllIndia);

//       if (isAllIndia) {
//         return {
//           "group": obj.NEState ? 'North East State' : obj.UT ? 'Union Territories' : 'States',
//           "country": rowName,
//           "Completed": scoreCompleted,
//           "HousesSanctioned": 100,
//           "SanctionValue": gSanctioned,
//           "CompletedValue": gCompleted,

//         }

//       } else {
//         return {
//           "country": rowName,
//           "Completed": scoreCompleted,
//           "HousesSanctioned": 100,
//           "SanctionValue": gSanctioned,
//           "CompletedValue": gCompleted,

//         }

//       }

//     });


//   // console.log('fnlGraphData===>>>>>>>>>', fnlGraphData);
//     return   fnlGraphData.sort((a, b) => {
//       return groupOrder.indexOf(a.group) - groupOrder.indexOf(b.group);
//     });
  }

  render() {
    return (
      <div
        id={this.props.id || 'LayeredColumnChart'}
        className={this.props.id || 'LayeredColumnChart'}
        style={{
          width: "100%",
          height: this.props.height || "412px",
          pointerEvents: "auto",

        }}
      />
    );
  }
}

export default LayeredColumnChart;
