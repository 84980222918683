import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import PropTypes from 'prop-types';

class ColumnChartFynancial extends Component {
  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.graphData !== this.props.graphData || prevProps.SchemesdataFilterType !== this.props.SchemesdataFilterType) {
      this.updateChartData();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  initChart() {
    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(
      this.props.id || 'ColumnChartFynancial',
      am4charts.XYChart
    );

    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.dy = 0;




    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis.min = 0; // Ensure y-axis starts from 0
    valueAxis.renderer.labels.template.fontSize = 10;
  

    // Customize y-axis grid and labels
    // valueAxis.renderer.grid.template.stroke = am4core.color("#ccc");
    // valueAxis.renderer.grid.template.strokeOpacity = 0.5;
    // valueAxis.renderer.labels.template.fontSize = 12;
    // valueAxis.renderer.labels.template.fill = am4core.color("#333");
  
    // // Add and customize y-axis title
    // valueAxis.title.text = "Number of Visits";
    // valueAxis.title.fontSize = 14;
    // valueAxis.title.fill = am4core.color("#000");
    // valueAxis.title.fontWeight = "bold";

    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "visits";
    series.dataFields.categoryX = "country";
    series.name = "Visits";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    // series.columns.template.strokeWidth = 0.8;
    // series.columns.template.strokeOpacity = 0.8;

    // Remove column border
// let columnTemplate = series.columns.template;
// columnTemplate.strokeWidth = 0;
// columnTemplate.strokeOpacity = 0;

    series.columns.template.adapter.add("fill", function (fill, target) {
      const colors = [
        am4core.color("#09283a"),
        am4core.color("#00b09a"),
        am4core.color("#ffdd00"),
   
      ];
      return colors[target.dataItem.index % colors.length];
    });

 

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{percentagevalue}%";
    labelBullet.label.dy = -10;
    labelBullet.label.fontSize = 12;
  
    // Add a visibility adapter for the label
    labelBullet.label.adapter.add("text", function (text, target) {
      const country = target.dataItem.dataContext.country;
      if (country === "Released" || country === "Utilized") {
        return "{percentagevalue}%";
      }
      return ""; // Hide label for other countries
    });


    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 0;
    columnTemplate.strokeOpacity = 0;





    this.updateChartData();
  }

  updateChartData() {
    if (!this.props.graphData || !this.chart) return;

    this.chart.data = this.props.graphData;
  }

  render() {
    return (
      <div
        id={this.props.id || 'ColumnChartFynancial'}
        className={this.props.id || 'ColumnChartFynancial'}
        style={{
          width: this.props.width || '100%',
          height: this.props.height || '100%',
          ...this.props.style,
        }}
      />
    );
  }
}

ColumnChartFynancial.defaultProps = {
  graphData: [],
};

ColumnChartFynancial.propTypes = {
  id: PropTypes.string,
  graphData: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
};

export default ColumnChartFynancial;
