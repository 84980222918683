export const DEMO = { BLANK_LINK: "#!",};
export const FILE_PATH = 'https://pmay-urban.gov.in/drmc' //'https://apis.pmayu-drmc.in/v1' //



export const BASE_URL = process.env.NODE_ENV === 'development' ? 'https://localhost:8081' : 'https://dashboard.pmay-urban.gov.in/v1'
// export const BASE_URL = 'https://api.pmayu-drmc.in/v1'  // Development




export const appUrls={
  'Dashboard':[
    {
      name:'Dashboard', 
      url:'/',
      column:[
        {
          label:'Constituency',
          value:'Constituency',
        },{
          label:'Smart city',
          value:'SmartCity',
        },{
          label:'AMRUT City',
          value:'AMRUTCity',
        },{
          label:'Metropolitan',
          value:'Metropolitan',
        },{
          label:'Investment/Central Assistance/Employment and Materials',
          value:'investmentCAEmpAndMaterials',
        },{
          label:'Contribution in over all demand',
          value:'contributionInDemand',
        },{
          label:'Zone wise graph',
          value:'ZoneWiseGraph',
        },{
          label:'GeoTag Details Graph',
          value:'GeoTagGraph',
        }
      ]
    }
  ],
  'Dashboard20':[
    {
      name:'Dashboard 2.0', 
      url:'/dashboard20',
      column:[
        {
          label:'Constituency',
          value:'Constituency',
        },{
          label:'Smart city',
          value:'SmartCity',
        },{
          label:'AMRUT City',
          value:'AMRUTCity',
        },{
          label:'Metropolitan',
          value:'Metropolitan',
        },{
          label:'Investment/Central Assistance/Employment and Materials',
          value:'investmentCAEmpAndMaterials',
        },{
          label:'Contribution in over all demand',
          value:'contributionInDemand',
        },{
          label:'Zone wise graph',
          value:'ZoneWiseGraph',
        },{
          label:'GeoTag Details Graph',
          value:'GeoTagGraph',
        }
      ]
    }
  ],
  'Progress in Map':[
    {
      name:'Progress in Map', 
      url:'/mapview/demand-saturation',
    }
  ],
  'Monitoring Reports':[
    {
      name:'At a Glance', 
      url:'/reports/at-glance',
      column:[
        {label:'Central Assistance Released', value: 'CentralAssistanceReleased'},
        {label:'CFY Released', value: 'CentralAssistanceReleasedCFY'},
        {label:'Central Assistance Utilised', value: 'CentralAssistanceUtilised'},
        {label:'Unspent Balance', value: 'UnspentBalance'},
        {label:'Houses yet to be Occupied', value: 'HousesyettobeOccupied'},
        {label:'At-A-Glance', value: 'AtaGlance'},
        {label:'MoS-At-A-Glance', value: 'mosAtaGlance'},
        {label:'District', value: 'District'},
        {label:'City', value: 'City'},
        {label:'AssemConstCode', value: 'AssemConstCode'},
        {label:'ParlConstCode', value: 'ParlConstCode'}
      ]
    },
    {
      name:'At a Glance 2.0', 
      url:'/reports/at-glanceNew',
      column:[
        {label:'Central Assistance Released', value: 'CentralAssistanceReleased'},
        {label:'Central Assistance Utilised', value: 'CentralAssistanceUtilised'},
        {label:'Unspent Balance', value: 'UnspentBalance'},
        {label:'Houses yet to be Occupied', value: 'HousesyettobeOccupied'},
        {label:'At-A-Glance', value: 'AtaGlance'},
        {label:'MoS-At-A-Glance', value: 'mosAtaGlance'},
        {label:'District', value: 'District'},
        {label:'City', value: 'City'},
        {label:'AssemConstCode', value: 'AssemConstCode'},
        {label:'ParlConstCode', value: 'ParlConstCode'}
      ]
    },
    {
      name:'At a Glance 2',
      url:'/reports/at-glance-2'
    },
    {
      name:'At a Glance 3',
      url:'/reports/at-glance-3'
    },
    {
      name:'Comparative Report',
      url:'/reports/comparative-report'
    },
    {
      name:'Inspection History', 
      url:'/reports/inspection-history',
      column:[
        {
           label:'All View',
           value:'allView',
        }
      ]
  },
    {
      name:'CSMC Wise Monitoring Progress', 
      url:'/reports/csmc-wise-monitoring-progress',
      column:[
        {
          label:'All State',
          value:'allState',
        },
      {
        label:'No of Projects',
        value:'noOfProjects',
      },{
        label:'Project Cost',
        value:'projectCost',
      },{
        label:'Central Assistance Approved',
        value:'CAApproved',
      },{
        label:'Central Assistance Sanctioned',
        value:'CASanctioned',
      },{
        label:'Installment wise Released',
        value:'installmentReleased',
      },{
        label:'Total Central Assistance Released',
        value:'totalInstallmentReleased',
      },{
        label:'UC Received',
        value:'installmentUC',
      },{
        label:'Total UC Received',
        value:'totalInstallmentUC',
      },{
        label:'Houses Approved',
        value:'housesApproved',
      },{
        label:'Houses Funded',
        value:'housesCAReleased',
      },{
        label:'Houses Grounded for Construction',
        value:'housesGroundedForConstruction',
      },{
        label:'Stage wise Progress',
        value:'stageProgress',
      },{
        label:'GeoTag Progress',
        value:'GeoTagProgress',
      },{
        label:'Houses Completed',
        value:'housesCompleted',
      },{
        label:'Actual Completed',
        key:'actualCompleted',
      },{
        label:'Houses Occupied',
        value:'housesOccupied',
      },{
        label:'Houses Yet to Grounded',
        value:'housesYettoGrounded',
      },{
        label: 'Beneficiaries Attached',
        value: 'beneficiariesAttached',
      },{
        label: 'Beneficiaries Yet to Attached',
        value: 'beneficiariesattobeAttached',
      },{
        label: 'First Installment Released for Houses',
        value: 'FirstInstallmentReleasedforHouses',
      },{
        label: 'Second Installment Released for Houses',
        value: 'SecondInstallmentReleasedforHouses',
      },{
        label: 'Third Installment Released for Houses',
        value: 'ThirdInstallmentReleasedforHouses',
      },{
        label: 'Fund with Excess Houses',
        value: 'fundWithExcessHouses',
      },
      {
        label: 'Blance Central Assistance',
        value: 'BlanceCentralAssistance',
      },
      {
        label: 'First Installment Due',
        value: 'DUFirstInstallmentReleasedforHouses',
      },
      {
        label: 'Second Installment Due',
        value: 'DUSecondInstallmentReleasedforHouses',
      },
      {
        label: 'Third Installment Due',
        value: 'DUThirdInstallmentReleasedforHouses',
      },
      {
        label: 'Additional Funds may be Released',
        value: 'additionalFundsMayBeReleased',
      }, {
        label: 'Houses for additional Funds Released',
        value: 'housesForAdditionalFundsReleased',
      },
    ]
    },
    {
      name:'Status of Progress Respective Years', 
      url:'/reports/status-financial-physical-progress-respective-years',
      column:[{
        label:'Filter By',
        value:'FilterBy',
      },{
        label:'Do not Show Component',
        value:'DontShowComponent',
      },{
        label:'All india In one  time',
        value:'allIndia',
      },{
        label:'All state In one time',
        value:'AllState',
      },

        {
          label:'No of Projects',
          value:'noOfProjects',
        },{
          label:'Project Cost',
          value:'projectCost',
        },{
          label:'Central Assistance Approved',
          value:'CAApproved',
        },{
          label:'Central Assistance Sanctioned',
          value:'CASanctioned',
        },{
          label:'Installment wise Released',
          value:'installmentReleased',
        },{
          label:'Central Assistance Released',
          value:'totalInstallmentReleased',
        },{
          label:'Recovered',
          key:'Recovered',
        },{
          label:'UC Received',
          value:'installmentUC',
        },{
          label:'Total UC Received',
          value:'totalInstallmentUC',
        },{
          label:'Houses Approved',
          value:'housesApproved',
        },{
          label:'Houses Funded',
          value:'housesCAReleased',
        },
        {
          label:'Houses Grounded for Construction',
          value:'housesGroundedForConstruction',
        },{
          label:'Stage wise Progress',
          value:'stageProgress',
        },{
          label:'Houses Completed',
          value:'housesCompleted',
        },{
          label:'Actual Completed',
          value:'actualCompleted',
        },{
          label:'Houses Occupied',
          value:'housesOccupied',
        },{
          label:'Houses Yet to Grounded',
          value:'housesYettoGrounded',
        }
      ]
    },
    
    {name:'District wise progress', url:'/reports/district-wise-progress'},
    
    {
      name:'State Score Card', 
      url:'/reports/scorecard',
      column:[
        {
          label:'Scheme1',
          value:'Scheme1',
        },
        {
          label:'Scheme2',
          value:'Scheme2',
        },
        {
          label:'CSMC wise Monitoring of Progress',
          value:'CSMCWiseMonitoringOfProgress',
        },{
          label:'Financial & Physical Progress in Respective Years',
          value:'FinancialPhysicalProgressRespectiveYears',
        }
      ]
    },
    {
      name:'State Review', 
      url:'/reports/state-review',
      column:[
        {
          label:'Edit Issues Field',
          value:'editIssuesField',
        },
        {
          label:'Edit Comment Field',
          value:'editCommentField',
        },
        {
          label:'Year wise : Financial Progress under PMAY-U',
          value:'FinancialProgressunderPMAYU',
        },
        {
          label:'A: Projects',
          value:'AProjects',
        },
        {
          label:'B: Non-Projects',
          value:'BNonProjects',
        }
      ]
    },
    {name:'Investment', url:'/reports/investment'},

    {name:'Consumptions of Materials', url:'/reports/consumptions-of-materials'},
    {name:'SLTC/CLTC and HFAPoA Details', url:'/reports/Sltc-Cltc-Hfapoa-Details'},
    {name:'New Technologies', url:'/reports/NewTechnologies'},
    {name:'PPP Report', url:'/reports/PPPReport'},
    {name:'PM Inaugurated Projects', url:'/reports/pminaugurated'},

    {name:'Construction Cost', url:'/reports/construction-cost'},
    
   
    {name:'Year wise Release', url:'/reports/releases-yearwise'},
   
   
    
    {name:'Master PMAYU', url:'/reports/master-pmayu',
    column:[
      {
        label:'Investment Approved under PMAY(U)',
        value:'InvestmentApproved',
      },{
        label:'Investment in on-going projects under PMAY(U)',
        value:'Investmentinongoing',
      },{
        label:'Details of Central Assistance under PMAY(U)',
        value:'DetailsofCentralAssistance',
      },{
        label:'Category wise breakup of Sanctioned houses under PMAY (U)',
        value:'CategorywisebreakupofSanctionedhouses',
      },{
        label:'Head wise Central Assistance Released under PMAY(U)',
        value:'HeadwiseCentralAssistance',
      }
    ]},  
    {name:'FA for Update', url:'/reports/FA-for-update'},
    {name:'Financial Contribution', url:'/reports/financial-contribution'},
    {name:'Physical Progress Status', url:'/reports/physical-progress-details'},
    {name:'Financial Progress Status', url:'/reports/financial-progress-details'},
    {
      name:'Project at a glance', 
      url:'/reports/project-details-report', 
      allowFilter:true,
      column:[
        {
          label:'All state In one time',
          value:'AllState',
        },
        {
          label:'Project Code',
          value:'ProjectCode',
        },{
          label:'AnnexureID',
          value:'AnnexureID',
        },{
          label:'Component',
          value:'Component',
        },{
          label:'State',
          value:'State',
        },{
          label:'State Code',
          value:'stateCode',
        },{
          label:'District',
          value:'District',
        },{
          label:'District Code',
          value:'districtCode',
        },{
          label:'City',
          value:'City',
        },{
          label:'City Code',
          value:'cityCode',
        },{
          label:'Approved Date',
          value:'approvedDate',
        },{
          label:'Project Title',
          value:'ProjectTitle',
        },{
          label:'Scheme',
          value:'Scheme',
        },{
          label:'CSMC No.',
          value:'CSMCNo',
        },{
          label:'Project Cost',
          value:'projectCost',
        },{
          label:'Central Assistance Sanctioned',
          value:'CASanctioned',
        },{
          label:'State Grant',
          value:'StateGrant',
        },{
          label:'ULB Share',
          value:'ULBShare',
        },{
          label:'Bneneficiary Share',
          value:'BneneficiaryShare',
        },{
          label:'Other Share',
          value:'OtherShare',
        },{
          label:'Installment wise Released',
          value:'installmentReleased',
        },{
          label:'Central Assistance Released',
          value:'totalInstallmentReleased',
        },{
          label:'Recovered',
          value:'Recovered',
        },{
          label:'UC Received',
          value:'installmentUC',
        },{
          label:'Total UC Received',
          value:'totalInstallmentUC',
        },{
          label:'Adjustment Released',
          value:'installmentAdjustment',
        },{
          label:'Total Adjustment Released',
          value:'totalInstallmentAdjustment',
        },{
          label:'Houses Sanctioned',
          value:'housesSanctioned',
        },{
          label:'Houses New',
          value:'housesNew',
        },{
          label:'Houses Enahncement',
          value:'housesEnahncement',
        },{
          label:'Funds Disbursed',
          value:'fundsDisbursed',
        },{
          label:'Houses Grounded',
          value:'housesGrounded',
        },{
          label:'Houses Completed',
          value:'housesCompleted',
        },{
          label:'Houses Occupied',
          value:'housesOccupied',
        },{
          label:'Houses Plinth',
          value:'housesPlinth',
        },{
          label:'Houses Lintel',
          value:'housesLintel',
        },{
          label:'Houses Roof',
          value:'housesRoof',
        },{
          label:'Houses Yet to Grounded',
          value:'housesYettoGrounded',
        },{
          label:'Project feedback',
          value:'Projectfeedback',
        }
      ]
    },
   
    {name:'Physical Progress Tracking', url:'/reports/PhysicalProgressMonitoringNew'},
    {name:'Physical Progress Monitoring', url:'/reports/PhysicalProgressMonitoring'},
    {name:'Project Action plan', url:'/reports/project-action-plan'},
    {name:'AHP Progress Details', url:'/reports/StatewiseProgressDetails'},
    {name:'Non Starter House', url:'/reports/nonStarterHouses'},
    
    
    {name:'Status of Funds Released / State Wise', url:'/reports/Statewiseucstatus/city/'},
    
   
    
    
  

   
    
    {name:'Curtailed Houses', url:'/reports/StateWiseCurtailedHouses'},
  
    {name:'Financial & Physical Performance', url:'/reports/financialandphysical-performance'},
    

    
  ],
  'Physical & Financial Report':[
    {
      name:'Year wise Physical', 
      url:'/reports/year-wise-physical',
      column:[
        {
          label:'Houses Sanctioned',
          value:'housesSanctioned',
        },{
          label:'Houses Grounded',
          value:'housesGrounded',
        },{
          label:'Houses Completed',
          value:'housesCompleted',
        },{
          label:'Houses Occupied',
          value:'housesOccupied',
        }
      ]
    },
    {name:'Respective Year wise-physical', url:'/reports/respective-year-wise'},

    {
      name:'Year wise Financial', 
      url:'/reports/year-wise-financial',
      column:[
        {
          label:'No. of Project',
          value:'noOfProject',
        },{
          label:'Project Cost',
          value:'projectCost',
        },{
          label:'Central Assistance Approved',
          value:'CAApproved',
        },{
          label:'Central Assistance Released',
          value:'CAReleased',
        },{
          label:'Central Assistance Utilised',
          value:'CAUtilised',
        }
      ]
    },
    {name:'Respective Year wise-Financial', url:'/reports/respective-year-wise-financial'},
    {name:'City & Year wise Physical & Financial', url:'/reports/cityWise-year-wise-physical-financial'},
    {name:'District and City Wise Progress', url:'/reports/districtCityWiseProgress'},
    {name:'Financial Progress (Vertical wise)', url:'/weekly/financial-progress'},
    {name:'Physical Progress (Vertical wise)', url:'/weekly/physical-progress'},
    {name:'State wise excl RAY & CLSS', url:'/weekly/statewise-exclude-rayclss'},
    {
      name:'Physical & Financial Progress(Old)', 
      url:'/reports/physical-and-financial-progress',
      column:[
        {
          label:'All State',
          value:'AllState',
        },
        {
          label:'District wise',
          value:'Districtwise',
        },
        {
          label:'City wise',
          value:'Citywise',
        },
        {
          label:'City wise All Constituency',
          value:'cityAllConst',
        },
        {
          label:'UDA/Town wise',
          value:'UDATownwise',
        },
        {
          label:'Constituency wise',
          value:'Constituencywise',
        },
        {
          label:'District',
          value:'District',
        },
        {
          label:'City',
          value:'City',
        },
        {
          label:'City Code',
          value:'CityCode',
        },
        {
          label:'Project Name',
          value:'ProjectName',
        },
        {
          label:'Project code',
          value:'Projectcode',
        },
        {
          label:'No of Projects',
          value:'noOfProjects',
        },{
          label:'Project Cost',
          value:'projectCost',
        },{
          label:'Central Assistance Approved',
          value:'CAApproved',
        },{
          label:'Installment wise Released',
          value:'installmentReleased',
        },{
          label:'Total Central Assistance Released',
          value:'totalInstallmentReleased',
        },{
          label:'UC Received',
          value:'installmentUC',
        },{
          label:'Total UC Received',
          value:'totalInstallmentUC',
        },{
          label:'Houses Demand',
          value:'housesDemand',
        },{
          label:'Houses Approved',
          value:'housesApproved',
        },{
          label:'Houses Funded',
          value:'housesFunded',
        },{
          label:'Houses Grounded for Construction',
          value:'housesGroundedForConstruction',
        },{
          label:'Stage wise Progress',
          value:'stageProgress',
        },{
          label:'Houses Completed',
          value:'housesCompleted',
        },{
          label:'Houses Occupied',
          value:'housesOccupied',
        }
      ]
    },
    {
      name:'Physical & Financial Progress', 
      url:'/reports/physical-and-financial-progress-dynm',
      column:[
        {
          label:'All State',
          value:'AllState',
        },
        {
          label:'District wise',
          value:'Districtwise',
        },
        {
          label:'City wise',
          value:'Citywise',
        },
        {
          label:'Mapped City wise',
          value:'MappedCitywise',
        },
        {
          label:'UDA/Town wise',
          value:'UDATownwise',
        },
        {
          label:'Constituency wise',
          value:'Constituencywise',
        },
        {
          label:'Assembly wise',
          value:'Assemblywise',
        },
        {
          label:'No of Projects',
          value:'noOfProjects',
        },{
          label:'Project Cost',
          value:'projectCost',
        },{
          label:'Central Assistance Approved',
          value:'CAApproved',
        },{
          label:'Installment wise Released',
          value:'installmentReleased',
        },{
          label:'Total Central Assistance Released',
          value:'totalInstallmentReleased',
        },{
          label:'UC Received',
          value:'installmentUC',
        },{
          label:'Total UC Received',
          value:'totalInstallmentUC',
        },{
          label:'Houses Demand',
          value:'housesDemand',
        },{
          label:'Houses Approved',
          value:'housesApproved',
        },{
          label:'Houses Funded',
          value:'housesFunded',
        },{
          label:'Houses Grounded for Construction',
          value:'housesGroundedForConstruction',
        },{
          label:'Stage wise Progress',
          value:'stageProgress',
        },{
          label:'Houses Completed',
          value:'housesCompleted',
        },{
          label:'Houses Occupied',
          value:'housesOccupied',
        }
      ]
    },
    {
      name:'Physical Progress ULB', 
      url:'/reports/physical-progress-ULB',
      column:[
        {
          label:'All State',
          value:'AllState',
        },
        {
          label:'District wise',
          value:'Districtwise',
        },
        {
          label:'City wise',
          value:'Citywise',
        },
        {
          label:'UDA/Town wise',
          value:'UDATownwise',
        },
        {
          label:'ULB Type',
          value:'ULBType',
        },
        {
          label:'Constituency wise',
          value:'Constituencywise',
        },
        {
          label:'No of Projects',
          value:'noOfProjects',
        },{
          label:'Project Cost',
          value:'projectCost',
        },{
          label:'Central Assistance Approved',
          value:'CAApproved',
        },{
          label:'Installment wise Released',
          value:'installmentReleased',
        },{
          label:'Total Central Assistance Released',
          value:'totalInstallmentReleased',
        },{
          label:'UC Received',
          value:'installmentUC',
        },{
          label:'Total UC Received',
          value:'totalInstallmentUC',
        },{
          label:'Houses Demand',
          value:'housesDemand',
        },{
          label:'Houses Approved',
          value:'housesApproved',
        },{
          label:'Houses Funded',
          value:'housesFunded',
        },{
          label:'Houses Grounded for Construction',
          value:'housesGroundedForConstruction',
        },{
          label:'Stage wise Progress',
          value:'stageProgress',
        },{
          label:'Houses Completed',
          value:'housesCompleted',
        },{
          label:'Houses Occupied',
          value:'housesOccupied',
        }
      ]
    },
    {name:'StateWise Prog. incl CLSS excl RAY', url:'/weekly/statewise-incl-clss-exc-ray'},
    {name:'StateWise Prog. incl. RAY excl CLSS', url:'/weekly/statewise-incl-ray-exc-clss'},
    {name:'StateWise Prog. incl RAY & CLSS', url:'/weekly/statewise-incl-ray-and-clss'},
  ],

  'CLSS Details':[
    {name:'CLSS State And CNA Wise', url:'/reports/clss-statewise'},
    {name:'CLSS City Wise', url:'/reports/ClssCityWise'},
    {name:'CLSS City & YearWise', url:'/reports/ClssCityWiseYearWise'},

  ],

  'Releases & UC Details':[
    {name:'Head wise Release', url:'/reports/releases-headwise'},
    {name:'Installment / Head Wise', url:'/reports/InstallmentHeadWise'},
    {name:'Actual Released', url:'/reports/ActualReleased'},
    {name:'PMAY-U Liability', url:'/reports/pmay-u-liablity'},
    {name:'Excess CA Balance', url:'/reports/exces-ca-balance'},
    {name:'Recalled Amount Adjusted', url:'/reports/RecalledAmountAdjusted'},
    {
      name:'Release Analysis', 
      url:'/reports/csmc-wise-release-fund', 
      allowFilter:true,
      column:[
        {
           label:'State Code',
           value:'stateCode',
         },{
           label:'District',
           value:'District',
         },{
           label:'District Code',
           value:'districtCode',
         },{
           label:'City',
           value:'City',
         },{
           label:'City Code',
           value:'cityCode',
         },{
           label:'CSMC Date',
           value:'approvedDate',
         },{
           label:'CSMC No.',
           value:'CSMCNo',
         },{
          label:'Project Title',
           value:'ProjectTitle',
         },{
           label:'Central Assistance Sanctioned',
           value:'CASanctioned',
         },{
           label:'Central Assistance Released',
           value:'totalInstallmentReleased',
         },{
           label:'Balance for Release',
           value:'BalanceforRelease',
         },{
           label:'Total UCs Received',
           value:'TotalUCsReceived',
         },{
           label:'Houses Sanctioned',
           value:'housesSanctioned',
         },{
           label:'Houses Grounded',
           value:'housesGrounded',
         },{
           label:'Houses Completed',
           value:'housesCompleted',
         },{
           label:'Beneficiary Attached',
           value:'BeneficiariesAttached',
         },{
           label:'Beneficiary Pending for Attached',
           value:'BeneficiaryPendingforAttached',
         },{
           label:'1st Installment',
           value:'1stInstallment',
         },{
           label:'2nd Installment',
           value:'2ndInstallment',
         },{
           label:'3rd Installment',
           value:'3rdInstallment',
         }
       ]
    },
    {name:'Release Analysis1', url:'/reports/release-analysis'},
    {name:'Expected Release', url:'/reports/expected-release'},
    {name:'Head wise Expected Release of Funds', url:'/reports/head-wise-expected-release'},
    {name:'Approved PAO Letter', url:'/reports/approved-pao-letter'},
    {name:'Head wise Release and UC', url:'/reports/headwise-release-uc'},
    {name:'Status of Funds Released', url:'/reports/Statewiseucstatus'},
    {name:'Year Wise Released & UC Received', url:'/reports/YearWiseReleasedAndUCRecieved'},
    {name:'Installment Wise Released & UC Received', url:'/reports/InstallmentWiseReleaseAndUC'},
    {name:'Non Projects Release and UC', url:'/reports/NonProjectRelease'},
    {name:'yearWise Released Vs UC Received', url:'/reports/yearWiseReleaseVsUCReceived'},
  ],
  'GeoTagged Report':[
    {
      name:'Geo-Tag Actual',
      url:'/weekly/GeoTagDetails',
      allowFilter:true,
      column:[
          {
           label:'Show Financial Year',
           value:'ShowFY',
          },{
            label:'State Wise / District Wise / City Wise / Project Wise',
            value:'SDCPWise',
          }
       ]
    },
    {
      name:'Status of Houses Sanctioned', 
      url:'/reports/StatusofHousesSanctioned',
      allowFilter: true,
      column: [
        {
          label: 'All State', 
          value: 'AllState' 
        }, 
        
        {
          label: 'Plinth', 
          value: 'Plinth' 
        },
        {
          label: 'Lintel', 
          value: 'Lintel' 
        },
        {
          label: 'Roof', 
          value: 'Roof' 
        },
        {
          label: 'Completed', 
          value: 'Completed' 
        },
        
      ]
    },
    {
      name:'Geo-Tag Progress-BLC',
      url:'/weekly/GeoTagLikelyDetails',
      allowFilter:true,
      column:[
          {
            label:'District Wise',
            value:'DistrictWise',
          },
          {
            label:'City Wise',
            value:'CityWise',
          },
          {
            label:'Project Wise',
            value:'ProjectWise',
          },
          {
            label:'Show Financial Year',
            value:'ShowFY',
          },
          {
            label: "Central Assistance Sanctioned",
            value: "CentralAssistanceSanctioned"
          },
          {
            label: "Central Assistance Released",
            value: "CentralAssistanceReleased"
          },
          {
            label: "Percent(%) Release",
            value: "PercentRelease"
          },
          {
            label: "Total not Started",
            value: "TotalNotStarted"
          },
          {
            label: "Likely for Curtailment",
            value: "LikelyForCurtailment"
          },
          {
            label: "Project Code / AnnexureID",
            value: "ProjectCodeAnn"
          },
       ]
    },
    {
      name:'Geo-Tag Progress-AHP/ISSR',
      url:'/weekly/GeoTagLikelyDetailsAHPISSR',
      allowFilter: true,
      column: [
        {
          label:'District Wise',
          value:'DistrictWise',
        },
        {
          label:'City Wise',
          value:'CityWise',
        },
        {
          label:'Project Wise',
          value:'ProjectWise',
        },
        { 
          label: 'Block No', 
          value: 'BlockNo' 
        }, 
        { 
          label: 'Total Flats', 
          value: 'TotalFlats' 
        }, 
        {
          label: 'GeoTag Stage', 
          value: 'GeoTagStage' 
        }, 
        { 
          label: 'GeoTag Date', 
          value: 'GeoTagDate' 
        }, 
        { 
          label: 'Images', 
          value: 'Images' 
        }
      ]
    },
    {name:'GeoTag Progress Tracking', url:'/reports/GeoTagProgressTracking'},
    {
      name:'Analysis of GeoTag', 
      url:'/reports/GeoTagAnalysis',
      allowFilter: true,
      column: [
        {
          label: 'All State', 
          value: 'AllState' 
        }, 
        {
          label: 'Houses Sanctioned', 
          value: 'housesSanctioned' 
        }, 
        {
          label: 'Geo Tag', 
          value: 'GeoTag' 
        },
        {
          label: 'Geo Tag but Not Started', 
          value: 'GeoTagbutNotStarted' 
        },
        {
          label: 'Plinth', 
          value: 'Plinth' 
        },
        {
          label: 'Lintel', 
          value: 'Lintel' 
        },
        {
          label: 'Roof', 
          value: 'Roof' 
        },
        {
          label: 'Completed', 
          value: 'Completed' 
        },
        {
          label: 'Completion', 
          value: 'Completion' 
        },
        {
          label: 'Taken Up', 
          value: 'TakenUp' 
        },
        {
          label: 'Fresh GeoTag', 
          value: 'FreshGeoTag' 
        },
      ]
    },
    {
      name:'Geo-Tag Performance',
      url:'/reports/GeoTagTracking',
      allowFilter:true,
      column:[
          {
            label:'All India',
            value:'allIndia',
          },
          {
            label:'District Wise',
            value:'DistrictWise',
          },
          {
            label:'City Wise',
            value:'CityWise',
          }
       ]
    },
    {
      name:'Geo-Tag GeoTagMonitoring',
      url:'/reports/GeoTagMonitoring',
      allowFilter:true,
      column:[
          {
            label:'All India',
            value:'allIndia',
          },
          {
            label:'District Wise',
            value:'DistrictWise',
          },
          {
            label:'City Wise',
            value:'CityWise',
          },
          {
            label:'Export Excel',
            value:'Export',
          },
       ]
    },
    {
      name:'Performance of Geo-tagging Stages',
      url:'/reports/GeoTagAVGPerformance',
      allowFilter:true,
      column:[
          {
            label:'All India',
            value:'allIndia',
          },
          {
            label:'District Wise',
            value:'DistrictWise',
          },
          {
            label:'City Wise',
            value:'CityWise',
          },
       
       ]
    },
    {
      name:'GeoTag NRSA Count Report',
      url:'/reports/GeoTagNRSACountReport',
      allowFilter:true,
    },
  ],
  'Beneficiaries Details':[
    {
      name:'Categories wise Houses', 
      url:'/reports/categories-wise-houses',
      allowFilter: true,
      column: [
        {
          label:'District Wise',
          value:'DistrictWise',
        },
        {
          label:'City Wise',
          value:'CityWise',
        },
        {
          label:'Project Wise',
          value:'ProjectWise',
        },
        {
          label:'CSMC No Wise',
          value:'CSMCNOWise',
        },
        {
          label:'As per DPR',
          value:'AsPerDPR',
        },
        {
          label:'As per Attachment',
          value:'AsPerAttachment',
        },
        {
          label:'As per MIS',
          value:'AsPerMIS',
        },
        {
          label:'Show Financial Year',
          value:'ShowFY',
        },
        {
          label:'Categories Wise',
          value:'CategoriesWise',
        },
        {
          label:'PWD / Sr. Citizen / Minority(Categories Wise)',
          value:'PWDCITIZENMINORITY',
        },
        {
          label:'Gender Wise',
          value:'GenderWise',
        },
        {
          label:'Religious Wise',
          value:'ReligiousWise',
        },
        {
          label:'As per beneficiaries Attachment - Grounded',
          value:'BenefGrounded',
        },
        {
          label:'As per beneficiaries Attachment - Completed',
          value:'BenefCompleted',
        },
      ]
    },
    {name:'Beneficiary Updates Report (CronJob)', url:'/admin-services/beneficiary-updates-report'},
    {name:'Beneficiary Data Updates', url:'/admin-services/beneficiary-data-updates'},
    {
      name:'Beneficiaries Details', 
      url:'/admin-services/beneficiaries-details',
      allowFilter: true,
      column:[
        {label:'All State',value:'AllState',},
        {label:'District wise',value:'Districtwise',},
        {label:'City wise',value:'Citywise',},
        {label:'Beneficiary Count Tab',value:'BeneficiaryCountTab',},
        {label:'Search Beneficiaries Box',value:'BeneficiariesSearch',},
        {label:'Beneficiary Code',value:'BeneficiaryCode',},
        {label:'Project Code',value:'ProjectCode',},
        {label:'AnnexureID',value:'AnnexureID',},
        {label:'Component',value:'Component',},
        {label:'State',value:'State',},
        {label:'State Code',value:'stateCode',},
        {label:'District',value:'District',},
        {label:'District Code',value:'districtCode',},
        {label:'City',value:'City',},
        {label:'City Code',value:'cityCode',},
        {label:'Beneficiary Name',value:'BeneficiaryName',},
        {label:'Father Name',value:'FatherName',},
        {label:'Religion',value:'Religion',},
        {label:'Caste',value:'Caste',},
        {label:'Gender',value:'Sex',},
        {label:'Age',value:'Age',},
        {label:'Occupation Name',value:'OccupationName',},
        {label:'Address',value:'Address',},
        {label:'Mobile No.',value:'MobileNo',},
        {label:'Accepted On Completed',value:'Accepted_On_Completed',},
        {label:'Accepted On Foundation',value:'Accepted_On_Foundation',},
        {label:'Accepted On Lintel',value:'Accepted_On_Lintel',},
        {label:'Accepted On NotStarted',value:'Accepted_On_NotStarted',},
        {label:'Accepted On Roof',value:'Accepted_On_Roof',},
        {label:'Accepted On Started',value:'Accepted_On_Started',},
        {label:'S1P1',value:'S1P1',},
        {label:'S1P2',value:'S1P2',},
        {label:'S2P1',value:'S2P1',},
        {label:'S2P2',value:'S2P2',},
        {label:'S3P1',value:'S3P1',},
        {label:'S3P2',value:'S3P2',},
        {label:'S4P1',value:'S4P1',},
        {label:'S4P2',value:'S4P2',},
        {label:'S5P1',value:'S5P1',},
        {label:'S5P2',value:'S5P2',},
        {label:'S6P1',value:'S6P1',},
        {label:'S6P2',value:'S6P2',},
        {label:'Stage 1',value:'Stage_1',},
        {label:'Stage 2',value:'Stage_2',},
        {label:'Stage 3',value:'Stage_3',},
        {label:'Stage 4',value:'Stage_4',},
        {label:'Stage 5',value:'Stage_5',},
        {label:'Stage 6',value:'Stage_6',},
        {label:'Whether slum beneficiary',value:'Whether_slum_beneficiary',},
        {label:'SLUM Name',value:'SLUM_Name',},
        {label:'Ward Name',value:'Ward_Name',},
        {label:'CLSS Beneficiary Code',value:'BENEFICIARY_CODE',},
        {label:'CLSS PLI Category',value:'PLI_CATEGORY',},
        {label:'CLSS Borrower Name',value:'BORROWER_NAME',},
        {label:'CLSS Gender',value:'SEX',},
        {label:'CLSS UI Proof',value:'UI_PROOF',},
        {label:'CLSS UI Proof Other',value:'UI_PROOF_OTHER',},
        {label:'CLSS UI No',value:'UINO',},
        {label:'CLSS PAN No',value:'PAN_NO',},
        {label:'CLSS House Type',value:'HOUSE_TYPE',},
        {label:'CLSS Prefered Category Others',value:'PREFERED_CATEGORY_OTHERS',},
        {label:'CLSS PRES Address H.No',value:'PRES_ADDRESS_HOUSE_NO',},
        {label:'CLSS PRES Address Street Name',value:'PRES_ADDRESS_STREET_NAME',},
        {label:'CLSS PRES Address Dist Name',value:'PRES_ADDRESS_DISTRICT_NAME',},
        {label:'CLSS PRES Address PinCode',value:'PRES_ADDRESS_PIN_CODE',},
        {label:'CLSS Mobile No',value:'MOB_NO',},
        {label:'CLSS Category Caste',value:'CATEGORY_CASTE',},
        {label:'CLSS CateGory Religion',value:'CATEGORY_RELIGION',},
        {label:'CLSS CNA ID',value:'CNA_ID',},
        {label:'CLSS PLI Code',value:'PLI_CODE',},
        {label:'CLSS Record Id',value:'RECORD_ID',},
        {label:'CLSS Application Id',value:'APPLICATION_ID',},
        {label:'CLSS Religion Other',value:'RELIGION_OTHER',},
        {label:'CLSS Preferred Category',value:'PREFERRED_CATEGORY',},
        {label:'CLSS Co-Borrower Name',value:'CO_BORROWER_NAME',},
        {label:'CLSS Co-Gender',value:'CO_SEX',},
        {label:'CLSS Co-UI Proof',value:'CO_UI_PROOF',},
        {label:'CLSS Co-UI Proof Other',value:'CO_UI_PROOF_OTHER',},
        {label:'CLSS Co-UINO',value:'CO_UINO',},
        {label:'CLSS Co-PAN No',value:'CO_PAN_NO',},
        {label:'CLSS HHS Category',value:'HHS_CATEGORY',},
        {label:'CLSS HHS-Annual Income',value:'HHS_ANNUAL_INCOME',},
        {label:'CLSS Property Type',value:'PROPERTY_TYPE',},
        {label:'CLSS Property Area',value:'PROPERTY_AREA',},
        {label:'CLSS PROP Address H.No',value:'PROP_ADDRESS_HOUSE_NO',},
        {label:'CLSS PROP Address Street Name',value:'PROP_ADDRESS_STREET_NAME',},
        {label:'CLSS PROP Address City Code',value:'PROP_ADDRESS_CITY_CODE',},
        {label:'CLSS PROP Address Dist Code',value:'PROP_ADDRESS_DISTRICT_CODE',},
        {label:'CLSS PROP Address State Code',value:'PROP_ADDRESS_STATE_CODE',},
        {label:'CLSS PROP Address PinCode',value:'PROP_ADDRESS_PIN_CODE',},
        {label:'CLSS Ownership Mode',value:'OWNERSHIP_MODE',},
        {label:'CLSS Loan Source',value:'LOAN_SOURCE',},
        {label:'CLSS Loan Purpose',value:'LOAN_PURPOSE',},
        {label:'CLSS Loan Account No',value:'LOAN_ACC_NO',},
        {label:'CLSS Loan Amount',value:'LOAN_AMOUNT',},
        {label:'CLSS Loan Int Rate',value:'LOAN_INT_RATE',},
        {label:'CLSS Loan Tenure',value:'LOAN_TENURE',},
        {label:'CLSS Loan Moratorium',value:'LOAN_MORATORIUM',},
        {label:'CLSS Water',value:'IS_WATER',},
        {label:'CLSS Electricity',value:'IS_ELECTRICITY',},
        {label:'CLSS Drainage',value:'IS_DRAINAGE',},
        {label:'CLSS Refund',value:'RefundYes',}
      ]
    },
    {name:'Beneficiaries NHBPMAY', url:'/admin-services/NHB_PMAY'},
  ],


  'Slum Details':[
    {name:'Slums Details', url:'/reports/SlumsDetails'},
    {name:'Slums Count Report', url:'/reports/SlumsCountReport'},
    {name:'Slums Details Project Wise', url:'/reports/SlumsDetailsProjectWise'},
  ],
  
  'JnNURM':[
    {name:'JnNURM state wise', url:'/weekly/jnnurm-state-wise'},
    {name:'JnNURM Dist-City Wise', url:'/weekly/jnnurm-dist-city-wise'},
  ],


  'Reports':[
   
  
    
    {name:'State wise progress excl RAY', url:'/weekly/statewiseprogress-exclray'},
   
    {name:'RAY state wise', url:'/weekly/ray-state-wise'},
    {name:'Issues under PMAY-U', url:'/weekly/excl-ray-and-clss'},
    {name:'Demand Status', url:'/weekly/demand-status'},


    {name:'StateWise Prog. incl RAY & CLSS', url:'Wise'},
    {name:'Baseline Information', url:'/weekly/baseline-information'},
    {name:'At a Glance', url:'/weekly/at-glance'},
    
  ], 
  Master:[
    {name:'Major Scheme', url:'/master/major-scheme'},
    {name:'Scheme Component', url:'/master/scheme-component'},
    {name:'State Master', url:'/master/state'},
    {name:'State District', url:'/master/district'},
    {name:'State City', url:'/master/city'},
    {name:'Sub Component', url:'/master/sub-component'},
    {name:'Technology', url:'/master/technology'},
    {name:'Material', url:'/master/material'},
    {name:'Project & Technology Mapping', url:'/master/project-technology-mapping'},
    {name:'Classification', url:'/master/Classification'},
    {name:'Classification Mapping', url:'/master/ClassificationMap'},
    {name:'Parliament Assembly Mapping', url:'/master/parliament-assembly-mapping'},
    {name:'Constituency', url:'/master/constituency'},
    {name:'Assembly', url:'/master/assembly'},
    {name:'Implementing Agency', url:'/master/implementing-agency'},
    {name:'Likely Curtailment Master', url:'/master/likely-curtailment-master'},
  ],
  'PRAYASH Data':[
    {name:'5 KPI', url:'/prayash/5kpi'},
    {name:'3 KPI', url:'/prayash/3kpi'},
    {name:'14 KPI', url:'/prayash/14kpi'},
    {name:'22 KPI', url:'/prayash/22kpi'},
    // {name:'42 KPI', url:'/prayash/42kpi'},
  ],
  'Audit Data':[
    {name:'Documents', url:'/reports/auditDetails'},
   
  ],
  'MIS Data':[
    {name:'DashBoard ProjectWise', url:'/mis/dashBoardProjectWise'},
    {name:'MIS-MPR Progress of Project', url:'/weekly/mis-progressProject'},
    {name:'Comparision-Report', url:'/weekly/comparision-Report'},


  ],
  'Admin Services':[
    {name:'Project Brif Shortfall Details', url:'/admin-services/project-shortfall-details'},
    {name:'API Log Details', url:'/admin-services/api-log-details'},
    {
      name:'Inspection History', 
      url:'/admin-services/inspection-history',
      column:[
        {
           label:'All View',
           value:'allView',
        }
      ]
  },

  ],
  'Data Entry':[
    {name:'CLSS State Wise', url:'/entry/clss-statewise'},
    {name:'Upload CLSS', url:'/entry/clss-statewise/upload'},
    {name:'Project Information', url:'/entry/project-information'},
    {name:'Physical Progress', url:'/entry/physical-progress'},
    {name:'Physical Progress Submission', url:'/entry/physical-progress-submission'},
    {name:'Add Cumulative Release', url:'/entry/add-cumulative-release'},
    {name:'Releases & Fundflow', url:'/entry/releases-fundflow'},
    {name:'Upload Releases', url:'/entry/release/upload'},
    {name:'Excess CA Pool', url:'/entry/excess-ca-pool'},
    {name:'UC-Submission', url:'/entry/uc-submission'},
    {
      name:'Sanction And Utilization Order', 
      url:'/entry/sanction-utilization',
      column:[
        {
          label:'Sanction Order',
          value:'sanctionOrder',
        },{
          label:'Utilization Order',
          value:'utilizationOrder',
        },
        {
          label:'Add',
          value:'AddRel',
        },
        {
          label:'Add',
          value:'AddUC',
        },
        {
          label:'Edit',
          value:'sanctionOrderEdit',
        },{
          label:'UC Submission / Rollback',
          value:'ucSubmissionRollback',
        },
        { label:'Delete',
          value:'sanctionOrderDelete',
        },{
          label:'Edit',
          value:'ucSubmissionEdit',
        },
        { label:'Upload Project wise Release',
          value:'uploadProjectWiseRelease',
        },
        { label:'Delete',
          value:'ucSubmissionDelete',
        },
        { label:'Single Project wise Release',
          value:'singleProjectWiseRelease',
        },
        { label:'Project label UC Submission',
          value:'projectWiseUCSubmission',
        },

        { label:'CLSS',
          value:'CLSSOrder',
        },
        { label:'BMTPC',
          value:'BMTPCOrder',
        },
        { label:'Other',
        value:'OtherOrder',
      },
      ]
    },
    {name:'Project Brief Details', url:'/entry/project-brief-details'},
    {name:'Minutes', url:'/entry/minutes'},
    {name:'State Score Card',
     url:'/entry/scorecard',
     column:[
       {label:'Housing Shortage',
      value:'HousingShortage',
    },
     {label:'MIS Information',
    value:'MISInformation',
    },
    {label:'ARHC Model',
    value:'ARHCModel',
    },
    {label:'HFAPoA/SLTC/CLTC Details',
    value:'HFAPoASLTCCLTCDetails',
    },
    {label:'Reforms',
    value:'Reforms',
    },
    {label:'JnNURM & RAY Details',
    value:'JnNURMRAYDetails',
    },
    {label:'UC Received Details',
    value:'UCReceivedDetails',
    },
     ]
  
  
  },

    {name:'Demand Survey', url:'/entry/demand-survey'},
    {name:'Document Upload', url:'/entry/document-upload'},
    {name:'Implementing Agency map with project', url:'/entry/implementing-agency-project'},
    {name:'PAO Letter List', url:'/entry/paolist'},
    {name:'PAO Letter Create/Update', url:'/entry/paoletter', 
    column:[
      {
        label:'Create Letter',
        value:'CreateLetter',
      },
      {
        label:'Approved',
        value:'Approved',
      },
      {
        label:'Save as Draft Button',
        value:'SaveAsDraft',
      },
      {
        label:'Final Submit Button',
        value:'FinalSubmit',
      }
    ]
    },
    {name:'CLSS City Wise', url:'/entry/clss-citywise'},
    {name:'Upload Category Wise', url:'/entry/UploadCategoryWise'},
    {name:'Recalled Excess CA Pool', url:'/entry/recalled-excess-ca-pool'},
    {name:'Upload Geo-Tag Annexure Wise', url:'/admin-services/action'},
    {name:'GeoTag Data Updates', url:'/admin-services/geotag-data-updates'},

    {name:'Annexure Update', url:'/admin-services/annexure-update'},
    {name:'MainReportUpdate', url:'/admin-services/mainReportUpdate'},
    {name:'VIP Reference', url:'/entry/vip-reference'},

    
  ],
  CMS:[
    {name:'Dashboard', url:'/cms/dashboard'},
    {name:'Shortfall', url:'/cms/shortfall'}
  ],
  
}

export const designationOpt = [
  {
    label: "Chief Secretary",
    value: "Chief Secretary",
  },
  {
    label: "Principal Secretary",
    value: "Principal Secretary",
  },
  {
    label: "Additional Principal Secretary",
    value: "Additional Principal Secretary",
  },
  {
    label: "Special Secretary",
    value: "Special Secretary",
  },
  {
    label: "Additional Secretary",
    value: "Additional Secretary",
  },
  {
    label: "Joint Secretary",
    value: "Joint Secretary",
  },
  {
    label: "Mission Director",
    value: "Mission Director",
  },
  { label: "Director", value: "Director" },
  {
    label: "Chief Engineer",
    value: "Chief Engineer",
  },
  {
    label: "Superintendent Engineer",
    value: "Superintendent Engineer",
  },
  {
    label: "Municipal Engineer",
    value: "Municipal Engineer",
  },
  { label: "Architect", value: "Architect" },
  {
    label: "Chief Town Planner",
    value: "Chief Town Planner",
  },
  {
    label: "Urban Town Planner",
    value: "Urban Town Planner",
  },
  {
    label: "Additional Director",
    value: "Additional Director",
  },
  {
    label: "Social Development Specialist",
    value: "Social Development Specialist",
  },
  {
    label: "GIS Specialist",
    value: "GIS Specialist",
  },
  {
    label: "Deputy Director",
    value: "Deputy Director",
  },
  {
    label: "Deputy Director Genral",
    value: "Deputy Director Genral",
  },
  {
    label: "Assistant Director",
    value: "Assistant Director",
  },
  {
    label: "Technical Director",
    value: "Technical Director",
  },
  {
    label: "PS",
    value: "PS",
  },
  {
    label: "Additional PS",
    value: "Additional PS",
  },
  {
    label: "PPS",
    value: "PPS",
  },
  {
    label: "OSD",
    value: "OSD",
  },
  {
    label: "SSA",
    value: "SSA",
  },
  {
    label: "Economic Adviser",
    value: "Economic Adviser",
  },
  {
    label: "Under Secretary",
    value: "Under Secretary",
  },
  {
    label: "Section Officer",
    value: "Section Officer",
  },
  {
    label: "Chief Executive",
    value: "Chief Executive",
  },
  {
    label: "CMD",
    value: "CMD",
  },
  {
    label: "ED",
    value: "ED",
  },
  {
    label: "Chief Finance",
    value: "Chief Finance",
  },
  {
    label: "Chief PMT and Admin",
    value: "Chief PMT and Admin",
  },
  {
    label: "Office Assistant",
    value: "Office Assistant",
  },
  {
    label: "DEO",
    value: "DEO",
  },

]




export const designationOpt1 = [
  { label: "Student", value: "Student" },
  {
    label: "Entrepreneur",
    value: "Entrepreneur",
  },
  { label: "Consultant", value: "Consultant" },
  {
    label: "Press/Media",
    value: "Press/Media",
  },
  {
    label: "SLTC",
    value: "SLTC",
  },
  {
    label: "CLTC",
    value: "CLTC",
  },
  {
    label: "Site Engineer",
    value: "Site Engineer",
  },
  {
    label: "Surveyor",
    value: "Surveyor",
  },
  {
    label: "Project Manager",
    value: "Project Manager",
  },
  {
    label: "MIS Specialist",
    value: "MIS Specialist",
  },
  {
    label: "DEO",
    value: "DEO",
  },
]


export const role1 = [
  { label: "admin", value: "admin" },
  {
    label: "City",
    value: "City",
  },
  {
    label: "CLTC",
    value: "CLTC",
  },
  {
    label: "Regional Coordinator",
    value: "Regional Coordinator",
  },
  {
    label: "PMU",
    value: "PMU",
  },
  {
    label: "District",
    value: "District",
  },
  {
    label: "LokSabha",
    value: "LokSabha",
  },
  {
    label: "Mission Director",
    value: "Mission Director",
  },
  {
    label: "Principle Secretary",
    value: "Principle Secretary",
  },
  {
    label: "SLNA",
    value: "SLNA",
  },
  {
    label: "SLTC",
    value: "SLTC",
  },
  {
    label: "DEO",
    value: "DEO",
  },
  {
    label: "DRMC",
    value: "DRMC",
  },
  {
    label: "HFA",
    value: "HFA",
  },
  { label: "Public", value: "Public" },

]





export const roleCenter = [
  { label: "admin", value: "admin" },
  {
    label: "DEO",
    value: "DEO",
  },
  {
    label: "DRMC",
    value: "DRMC",
  },
  {
    label: "HFA",
    value: "HFA",
  },{
    label: "Mission Director",
    value: "Mission Director",
  },
  {
    label: "Under Secretary",
    value: "Under Secretary",
  },  
  {
    label: "PMU",
    value: "PMU",
  }, {
    label: "Regional Coordinator",
    value: "Regional Coordinator",
  },
]

export const roleState = [
  {
    label: "Principle Secretary",
    value: "Principle Secretary",
  },
  {
    label: "Mission Director",
    value: "Mission Director",
  },
  { label: "Public", value: "Public" },
  {
    label: "SLNA",
    value: "SLNA",
  },
  {
    label: "SLTC",
    value: "SLTC",
  },
]


export const roleCity = [
  {
    label: "City",
    value: "City",
  },
  {
    label: "CLTC",
    value: "CLTC",
  },
]


export const roleDistrict = [
  {
    label: "District",
    value: "District",
  },
]


export const roleParliamentarian = [
  {
    label: "LokSabha",
    value: "LokSabha",
  },
]
export const roleOther = [
  {
    label: "Other",
    value: "Other",
  },
]

