import React, { PureComponent } from "react";
import styled from "styled-components";
import { Row, Col, Card, Table } from "react-bootstrap";
import _ from 'lodash'
import { HideColumn } from './HideColumn'
import LayeredColumnChart from './LayeredColumnChart'
import get from 'lodash/get'

import { Loader } from './Loader'

class CompletedSanctioned extends PureComponent {
  state = {
    // excludeComponent:['AHP','ISSR','BLC','RAY'],
    excludeComponent: [2, 3, 1, 7, 11, 10, 12, 13],
    totaldata: '',
    totalisAllIndia: '',
    totalisState: '',
    totalisDist: '',
    totalstatesList: '',
    totaldistrictList: '',
    totalcityList: '',
    isLoading: true,
  };
  componentDidMount() {

    this.setState({
      isLoading: false,

    })
  }

  componentDidUpdate() {


    this.setState({
      isLoading: true,

    })
    this.setState({

      isLoading: false,

    })

  }

  render() {
    const {
      data = [],
      isAllIndia,
      isState,
      isDist,
      statesList = [],
      districtList = [],
      cityList = [],
      SchemesdataFilterType,
      propsisLoading
    } = this.props;

    const {
      excludeComponent,
      isLoading
    } = this.state

console.log('SchemesdataFilterType---under-->>>>>>>>',SchemesdataFilterType);
    const grfData = SchemesdataFilterType === 'PMAYU' ? _.filter(data, f => excludeComponent.indexOf(_.get(f, 'comp_uniq_id')) !== -1 && (f.scheme_uniq_id === 3 || f.scheme_uniq_id === 2)) :
    SchemesdataFilterType === 'PMAYU2' ?  _.filter(data, f => excludeComponent.indexOf(_.get(f, 'comp_uniq_id')) !== -1 && f.scheme_uniq_id === 4):_.filter(data, f => excludeComponent.indexOf(_.get(f, 'comp_uniq_id')) !== -1)

    const calculateProgressGraphData = (type) => {
      const groupOrder = ["States", "Union Territories", "North East State"]
      const fnlGraphData = _.map(isAllIndia ? statesList : isState ? districtList : isDist ? cityList : [], (obj) => {
        const rowCode = isAllIndia ? _.get(obj, 'statecode') : isState ? _.get(obj, 'DistrictCode') : isDist ? _.get(obj, 'CityCode') : ''
        const rowName = isAllIndia ? _.get(obj, 'statename') : isState ? _.get(obj, 'DistrictName') : isDist ? _.get(obj, 'CityName') : ''

        const groupData = _.filter(grfData, f => isAllIndia ? _.get(f, 'StateCode') === rowCode : isState ? _.get(f, 'DistrictCode') === rowCode : isDist ? _.get(f, 'CityCode') === rowCode : false)
    

        const gSanctioned = _.sumBy(groupData, sm => Number(_.get(sm, 'HousesSanctionedEnahncement') || 0) + Number(_.get(sm, 'HousesSanctionedNew') || 0))
        const gGrounded = _.sumBy(groupData, sm => _.get(sm, 'totalWorkOrders') ? _.get(sm, 'totalWorkOrders') : 0)
        const gCompleted = _.sumBy(groupData, sm => _.get(sm, 'completed') ? _.get(sm, 'completed') : 0)
        const scoreGrounded = Math.round((gGrounded / gSanctioned) * 100) > 100 ? 100 : Math.round((gGrounded / gSanctioned) * 100)
        const scoreCompleted = Math.round((gCompleted / gSanctioned) * 100) > 100 ? 100 : Math.round((gCompleted / gSanctioned) * 100)
     

        if (isAllIndia) {
          return {
            "group": obj.NEState ? 'North East State' : obj.UT ? 'Union Territories' : 'States',
            "country": rowName,
            "Completed": scoreCompleted,
            "HousesSanctioned": 100,
            "SanctionValue": gSanctioned,
            "CompletedValue": gCompleted,

          }

        } else {
          return {
            "country": rowName,
            "Completed": scoreCompleted,
            "HousesSanctioned": 100,
            "SanctionValue": gSanctioned,
            "CompletedValue": gCompleted,

          }

        }

      });


      // console.log('fnlGraphData===>>>>>>>>>', fnlGraphData);
      return fnlGraphData.sort((a, b) => {
        return groupOrder.indexOf(a.group) - groupOrder.indexOf(b.group);
      });
    }

    ////////////////////************Grounding/Completion Vs Sanctioned Graph Data***********///////////////////////
    const sanctionedVsGrounded = calculateProgressGraphData('sanctionVsGrounded');
    //  SchemesdataFilterType==='PMAYU' ? isDist  ? _.filter(calculateProgressGraphData('ground'),f=>f.score > 0) : calculateProgressGraphData('ground') : SchemesdataFilterType==='PMAYU2' ? isDist ? _.filter(calculateProgressGraphData('ground'),f=>f.score > 0) : calculateProgressGraphData('ground') :'';
    

    if (isLoading) {
      return (
        <Loader />
      )
    }
    // if (propsisLoading === false && isLoading === false) {
      return (
        <Row
          className="printParent"
          style={{
            pageBreakAfter: "always",
            display: "flex",
            margin: 0,
            background: '#72e5be'
          }}
        >
          <div md={12}>
            <HideColumn
              columnList={
                SchemesdataFilterType === 'PMAYU' ?
                  [
                    { title: "AHP", key: 2 },
                    { title: "BLC", key: 3 },
                    { title: "ISSR", key: 1 },
                    { title: "RAY", key: 7 },
                  ] :
                  [
                    { title: "AHP", key: 11 },
                    { title: "BLC", key: 10 },
                    { title: "ARH", key: 12 },
                    { title: "ISS", key: 13 },
                  ]
              }
              name="Show Component"
              hideColumn={this.state.excludeComponent}
              getHideColumn={(excludeComponent) => this.setState({
                excludeComponent,
                isLoading: true
              })}

            />
          </div>
          <div
            md={12}
            className="printChildSetup"
            style={{
              pageBreakAfter: "always",
              // width: "50%",
              // paddingRight: 15,
            }}
          >
            <Card>
              <Card.Body style={{ padding: 0 }}>
                {_.get(sanctionedVsGrounded, "length") ? (
                  <LayeredColumnChart
                    id={"LayeredColumnChart"}
                    data={sanctionedVsGrounded}

                  />
                ) : (
                  "Data not Found."
                )}
              </Card.Body>
            </Card>
          </div>
        </Row>
      )
    // }
  }
}

export default CompletedSanctioned;
