import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { async } from './../utils/index';
import get from 'lodash/get'
class PictorialChartHousesSanctioned extends Component {
  state={
    totalCategory:0 ,
  }

  componentDidMount() {

    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphData !== this.props.graphData ||
      prevProps.SchemesdataFilterType !== this.props.SchemesdataFilterType
    ) {

      this.updateChartData();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }


  initChart() {
    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(
      this.props.id || "PictorialChartHousesSanctioned",
      am4charts.SlicedChart
    );

    this.chart.width = am4core.percent(100);
    this.chart.height = am4core.percent(100);
    this.chart.layout = "horizontal";

    // Create column chart
    this.columnChart = this.chart.createChild(am4charts.XYChart);

    let categoryAxis = this.columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    let valueAxis = this.columnChart.xAxes.push(new am4charts.ValueAxis());
    // valueAxis.title.fontWeight = "bold";
    valueAxis.title.dy = -265;
    valueAxis.title.rotation = 0; 
    valueAxis.title.fontFamily = "Segoe UI";
    // valueAxis.title.valign = "top"; // Align title at the top



    let columnSeries = this.columnChart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = "value";
    columnSeries.dataFields.categoryY = "category";
    columnSeries.columns.template.strokeWidth = 0;
    columnSeries.columns.template.tooltipText = "{category}: {value}";
    columnSeries.columns.template.fillOpacity = 0.8;

    let labelBullet = columnSeries.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.text = "{valueX}";  
  labelBullet.label.fill = am4core.color("#000");  // Text color
  labelBullet.label.fontSize = 12;
  labelBullet.label.horizontalCenter = "left";  // Align left for full visibility
  labelBullet.label.textAlign = "start";  // Ensure full text is visible
  labelBullet.locationX = 1;  // Position labels outside the column if needed
  labelBullet.label.dx = 15;  // Adjust horizontal position to move it outside
  labelBullet.label.truncate = false;  // Prevent cutting text
  labelBullet.label.hideOversized = false;  // Always show full value

    // Create pie chart
    this.pieChart = this.chart.createChild(am4charts.PieChart);
    this.pieChart.innerRadius = am4core.percent(50);

    let pieSeries = this.pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.tooltipText = "{category}: {value}";
   pieSeries.slices.template.fillOpacity = 0.8;

    this.pieChart.legend = new am4charts.Legend();
    this.pieChart.legend.position = "bottom";  // Positions legend at the bottom
    this.pieChart.legend.valign = "middle";   // Aligns it in the center
    this.pieChart.legend.labels.template.text = "{category}";
     this.pieChart.legend.contentAlign = "left"; // Centers the legend items
     this.pieChart.legend.valueLabels.template.disabled = true;
    //  this.pieChart.legend.labels.template.fontSize = 8;
    
     this.pieChart.legend.itemContainers.template.width = am4core.percent(100 / 5);

     // Fix legend text to exactly 4 characters
     this.pieChart.legend.labels.template.adapter.add("text", function(text, target) {
      if (target.dataItem && target.dataItem.dataContext) {
          let category = target.dataItem.dataContext.category || "";
          return category.length > 4 ? `${category.substring(0, 4)}..` : category.padEnd(4, " ");
      }
      return "";
  });
    
    this.pieChart.legend.markers.template.width = 10;
    this.pieChart.legend.markers.template.height = 10;
    this.pieChart.legend.maxHeight = 50; // Adjust height
    this.pieChart.legend.itemContainers.template.togglable = false;

    let centerCircle = this.pieChart.seriesContainer.createChild(am4core.Circle);
    
    centerCircle.radius = 52; 
    centerCircle.fill = am4core.color("#FFFFFF");  // Set fill color
    centerCircle.stroke = am4core.color("#000000");  // Optional border
    centerCircle.strokeWidth = 2;

    // pieSeries.labels.template.disabled = true; // Disable labels
    // pieSeries.ticks.template.disabled = true; // Disable connector line

    // pieSeries.labels.template.disabled = false; // Enable 
    // pieSeries.labels.template.adapter.add("text", (text, target) => {
    //   return target.dataItem && target.dataItem.value
    //     ? Number(target.dataItem.value).toLocaleString('en-IN')
    //     : "";
    // });

    // this.chart.innerRadius = am4core.percent(45);
    pieSeries.slices.template
      .cursorOverStyle = [
        {
          "property": "cursor",
          "value": "pointer"
        }
      ];

   
   //  Set ticks means line of lebel
   
   // pieSeries.ticks.template.disabled = false;
  //  pieSeries.ticks.template.length = 5; // Reduce tick length
  //  pieSeries.ticks.template.stroke = am4core.color("#000");
  //  pieSeries.ticks.template.strokeWidth = 2;
  //  pieSeries.ticks.template.strokeOpacity = 0.8;
  //  pieSeries.ticks.template.strokeDasharray = "4,4";
  //  pieSeries.ticks.template.strokeLinecap = "round";
  //  pieSeries.ticks.template.defaultState.transitionDuration = 1000;
   
   
     // Pie chart level setting
  //  pieSeries.alignLabels = false;
  //  pieSeries.labels.template.bent = false;
  //  pieSeries.labels.template.radius = -5; // Moves labels closer
  //  pieSeries.labels.template.relativeRotation = 90; // Prevents rotation issues
  //  pieSeries.labels.template.padding(2, 5, 2, 5); // Reduce padding
  //  pieSeries.labels.template.margin(0, 5, 0, 5); // Reduce margin
   
  pieSeries.alignLabels = false;
  pieSeries.labels.template.bent = true;
  pieSeries.labels.template.radius = 7;
  pieSeries.labels.template.padding(0,0,0,0);
  pieSeries.ticks.template.disabled = true;


       pieSeries.labels.template.adapter.add("text", (text, target) => {
         return target.dataItem && target.dataItem.value
           ? Number(target.dataItem.value).toLocaleString('en-IN')
           : "";
       });   
   
   pieSeries.labels.template.background.fill = am4core.color("#fff");
   pieSeries.labels.template.background.stroke = am4core.color("#000");
   pieSeries.labels.template.background.strokeWidth = 0;
   pieSeries.labels.template.fontSize = 10;
   pieSeries.labels.template.background.cornerRadius = 9;
   
   
  
    let label1 = this.pieChart.seriesContainer.createChild(am4core.Label);
  
    label1.horizontalCenter = "middle";
    label1.fill = am4core.color("#FF8C00"); // Red color for Total
    label1.fontSize = 35;
    label1.fontWeight = 600;
    label1.dy = -30;

    this.label1 = this.pieChart.seriesContainer.createChild(am4core.Label);
    this.label1.horizontalCenter = "middle";
    this.label1.verticalCenter = "middle";
    this.label1.fill = am4core.color("#FF8C00");
    this.label1.fontSize = 16;

    


    // label1.text = `20`;
  
    label1.horizontalCenter = "middle";
    label1.verticalCenter = "middle";
    label1.fill = am4core.color("#FF8C00"); // Red color for Total
    label1.fontSize = 16;
    //  label1.fontWeight = 600;
    // label1.dy = -30;
    let symbol = this.pieChart.seriesContainer.createChild(am4core.Label);
    symbol.text = this.props.symbol;
    symbol.horizontalCenter = "middle";
    symbol.verticalCenter = "middle";
    symbol.fontSize = 16;
    symbol.y = -15;

    let unit = this.pieChart.seriesContainer.createChild(am4core.Label);
    unit.text = this.props.unit;
    unit.horizontalCenter = "middle";
    unit.verticalCenter = "middle";
    unit.fontSize = 12;
    unit.y = 17;



    // this.addCenterLabels();

    // Event handling for pie slices
    this.pieChart.events.on("ready", function () {

      const firstSlice = pieSeries.slices.getIndex(0);

      if (firstSlice) firstSlice.isActive = true;
    });

    let toggleCount = 0

    pieSeries.slices.template.events.on("toggled", (ev) => {
      toggleCount += 1
      if (ev.target.isActive) {
        pieSeries.slices.each((slice) => {
          if (slice !== ev.target) {
            slice.isActive = false;
          }
        });


        let activeSliceName = ev.target.dataItem.category;
        const selectedData = ev.target.dataItem.dataContext;
        this.columnChart.data = selectedData.breakdown;
        columnSeries.fill = ev.target.fill;

  // let maxCategoryValue = Math.max(...this.columnChart.data.map(item => item.value));
  //       valueAxis.min = 0;
  //       valueAxis.max = maxCategoryValue >0 ? maxCategoryValue : 4000000;
  //       let step = (valueAxis.max - valueAxis.min) / 3;
  //       valueAxis.renderer.minGridDistance = 50; 
  //       // Add manual breakpoints
  //       valueAxis.renderer.labels.template.adapter.add("text", function(text) {
  //         let value = Number(text);
  //         if (value === valueAxis.min || value === valueAxis.max || value === step || value === step * 2) {
  //           return text;
  //         } else {
  //           return ""; // Hide other labels
  //         }
  //       });

  if (toggleCount === 1) {
          this.updateChartData();
          columnSeries.columns.template.fill = am4core.color("#FF8C00");
          columnSeries.columns.template.stroke = am4core.color("#FF8C00");
          valueAxis.title.text = "Slum Beneficiaries";
        } else {
          if (ev.target.isActive) {
            columnSeries.columns.template.fill = ev.target.fill;
            columnSeries.columns.template.stroke = ev.target.fill;
            valueAxis.title.text = activeSliceName;
          }
          
        }
       
      



      }else{
              this.updateChartData();
            
              columnSeries.columns.template.fill = am4core.color("#FF8C00");
              columnSeries.columns.template.stroke = am4core.color("#FF8C00");
              valueAxis.title.text = "Slum Beneficiaries";
            }
    });
    
    this.updateChartData();


  }



  updateChartData() {
    if (!this.props.graphData) return;

    const { graphData, SchemesdataFilterType } = this.props;

    const data = this.prepareChartData(graphData, SchemesdataFilterType);
    if (this.chart) {
      const beneficiariesCategory = data.find(item => item.category === "Yet to Start");
      const stateCategory = data.find(item => item.category === "Under Construction");
      const centerCategory = data.find(item => item.category === "Completed");
      // const Totalvalue = data.find(item => item.category === "Total") ?data.find(item => item.category === "Total").;
      // console.log('Totalvalue--->>>>>>',Totalvalue);

  // Calculate the total
  const totalCategory = [
    beneficiariesCategory?.value || 0,
    stateCategory?.value || 0,
    centerCategory?.value || 0
].reduce((acc, value) => acc + Number(value), 0);

      const pieData = data.slice(1);
      this.pieChart.data = data.slice(1);
      this.columnChart.data = data[0].breakdown;
     
    this.setState({ totalCategory }, () => {
      if (this.label1) {
          this.label1.text = `${totalCategory.toLocaleString('en-IN')}`;
      }
  });


    }
  }



  prepareChartData(graphData, SchemesdataFilterType) {

    const datachart = graphData;
    console.log('datachart--->>>>>>>',datachart);
    const SchemesdataFilterType1 = SchemesdataFilterType;

    const totalvalue = Number(datachart.INVtotalAHP + datachart.INVtotalBLC + datachart.INVtotalISSR +
      datachart.INVtotalRAY + datachart.INVtotalCLSS + datachart.INVtotalARH).toFixed(0);

    const totalvaluecentral = Number(datachart.centralAssistAHP + datachart.centralAssistBLC +
      datachart.centralAssistISSR + datachart.centralAssistRAY + datachart.centralAssistCLSS + datachart.centralAssistARH).toFixed(0);

    const totalvalueState = Number(datachart.stateShareAHP + datachart.stateShareBLC + datachart.stateShareISSR +
      datachart.stateShareRAY + datachart.stateShareCLSS + datachart.stateShareARH).toFixed(0);

    const totalvaluebeneficiariesShare = Number(datachart.beneficiariesShareAHP + datachart.beneficiariesShareBLC +
      datachart.beneficiariesShareISSR + datachart.beneficiariesShareRAY + datachart.beneficiariesShareCLSS +
      datachart.beneficiariesShareARH).toFixed(0);

    return   SchemesdataFilterType1==='PMAYU' ? [
      {
        "category": "Total",
        "value": totalvalue,
        "color": am4core.color("#FF8C00"), // Red color for the Total category
        "breakdown": [
          { "category": "AHP", "value": datachart.INVtotalAHP },
          { "category": "BLC", "value": datachart.INVtotalBLC },
          { "category": "ISSR", "value": datachart.INVtotalISSR },
          // { "category": "ARH", "value": datachart.INVtotalARH },
          // { "category": "CLSS", "value": datachart.INVtotalCLSS }
        ]
      },
      {
        "category": "Completed",
        "value": totalvaluecentral,
        "color": am4core.color("#00b09a"),
        "breakdown": [
          { "category": "AHP", "value": datachart.centralAssistAHP },
          { "category": "BLC", "value": datachart.centralAssistBLC },
          { "category": "ISSR", "value": datachart.centralAssistISSR },
          // { "category": "ARH", "value": datachart.centralAssistARH },
          // { "category": "CLSS", "value": datachart.centralAssistCLSS },
        ]
      },
      {
        "category": "Under Construction",
        "value": totalvalueState,
        "color": am4core.color("#ffdd00"),
        "breakdown": [
          { "category": "AHP", "value": datachart.stateShareAHP },
          { "category": "BLC", "value": datachart.stateShareBLC },
          { "category": "ISSR", "value": datachart.stateShareISSR },
          // { "category": "ARH", "value": datachart.stateShareARH },
          // { "category": "CLSS", "value": datachart.stateShareCLSS },
        ]
      },
      {
        "category": "Yet to Start",
        "value": totalvaluebeneficiariesShare,
        "color": am4core.color("#09283a"),
        "breakdown": [
          { "category": "AHP", "value": datachart.beneficiariesShareAHP },
          { "category": "BLC", "value": datachart.beneficiariesShareBLC },
          { "category": "ISSR", "value": datachart.beneficiariesShareISSR },
          // { "category": "ARH", "value": datachart.beneficiariesShareARH },
          // { "category": "CLSS", "value": datachart.beneficiariesShareCLSS },
        ]
      }
    ]:SchemesdataFilterType1==='both' ?[
      {
        "category": "Total",
        "value": totalvalue,
        "color": am4core.color("#FF8C00"), // Red color for the Total category
        "breakdown": [
          { "category": "AHP", "value": datachart.INVtotalAHP },
          { "category": "BLC", "value": datachart.INVtotalBLC },
          { "category": "ISSR", "value": datachart.INVtotalISSR },
          // { "category": "ARH", "value": datachart.INVtotalARH },
          // { "category": "ISS", "value": datachart.INVtotalCLSS }
        ]
      },
      {
        "category": "Completed",
        "value": totalvaluecentral,
        "color": am4core.color("#00b09a"),
        "breakdown": [
          { "category": "AHP", "value": datachart.centralAssistAHP },
          { "category": "BLC", "value": datachart.centralAssistBLC },
          { "category": "ISSR", "value": datachart.centralAssistISSR },
          // { "category": "ARH", "value": datachart.centralAssistARH },
          // { "category": "ISS", "value": datachart.centralAssistCLSS },
        ]
      },
      {
        "category": "Under Construction",
        "value": totalvalueState,
        "color": am4core.color("#ffdd00"),
        "breakdown": [
          { "category": "AHP", "value": datachart.stateShareAHP },
          { "category": "BLC", "value": datachart.stateShareBLC },
          { "category": "ISSR", "value": datachart.stateShareISSR },
          // { "category": "ARH", "value": datachart.stateShareARH },
          // { "category": "ISS", "value": datachart.stateShareCLSS },
        ]
      },
      {
        "category": "Yet to Start",
        "value": totalvaluebeneficiariesShare,
        "color": am4core.color("#09283a"),
        "breakdown": [
          { "category": "AHP", "value": datachart.beneficiariesShareAHP },
          { "category": "BLC", "value": datachart.beneficiariesShareBLC },
          { "category": "ISSR", "value": datachart.beneficiariesShareISSR },
          // { "category": "ARH", "value": datachart.beneficiariesShareARH },
          // { "category": "ISS", "value": datachart.beneficiariesShareCLSS },
        ]
      }
    ] :[
      {
        "category": "Total",
        "value": totalvalue,
        "color": am4core.color("#FF8C00"), // Red color for the Total category
        "breakdown": [
          { "category": "AHP", "value": datachart.INVtotalAHP },
          { "category": "BLC", "value": datachart.INVtotalBLC },
          // { "category": "ARH", "value": datachart.INVtotalARH },
          // { "category": "ISS", "value": datachart.INVtotalCLSS }
        ]
      },
      {
        "category": "Completed",
        "value": totalvaluecentral,
        "color": am4core.color("#00b09a"),
        "breakdown": [
          { "category": "AHP", "value": datachart.centralAssistAHP },
          { "category": "BLC", "value": datachart.centralAssistBLC },
          // { "category": "ARH", "value": datachart.centralAssistARH },
          // { "category": "ISS", "value": datachart.centralAssistCLSS },
        ]
      },
      {
        "category": "Under Construction",
        "value": totalvalueState,
        "color": am4core.color("#ffdd00"),
        "breakdown": [
          { "category": "AHP", "value": datachart.stateShareAHP },
          { "category": "BLC", "value": datachart.stateShareBLC },
          // { "category": "ARH", "value": datachart.stateShareARH },
          // { "category": "ISS", "value": datachart.stateShareCLSS },
        ]
      },
      {
        "category": "Yet to Start",
        "value": totalvaluebeneficiariesShare,
        "color": am4core.color("#09283a"),
        "breakdown": [
          { "category": "AHP", "value": datachart.beneficiariesShareAHP },
          { "category": "BLC", "value": datachart.beneficiariesShareBLC },
          // { "category": "ARH", "value": datachart.beneficiariesShareARH },
          // { "category": "ISS", "value": datachart.beneficiariesShareCLSS },
        ]
      }
    ]
  }



  render() {
    return (
      <div
        id={this.props.id || 'PictorialChartHousesSanctioned'}
        className={this.props.id || 'PictorialChartHousesSanctioned'}
        style={{
          width: '150%',
          height: this.props.height || '500px',
          position: 'relative',
          left: '-50%',
      
        }}
      />
    );
  }
}

export default PictorialChartHousesSanctioned;
